import React, { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination } from "swiper"
import { DEVICES, SLIDER_PAGES } from "../../config"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"
import { css } from "styled-components"
import ChevronUp from "../../assets/images/icon-chevron-down.inline.svg"

SwiperCore.use([Pagination])

function Item({ children, summary, expanded, onClick }) {
  return (
    <AccordionItem
      dangerouslySetExpanded={expanded}
      css={`
        border-radius: 12px;
        box-shadow: 5px 5px 10px 0 #0a0a0a, -5px -5px 10px 0 #242424;
        font-size: 20px;
        line-height: 1.4;
        cursor: pointer;
      `}
      onClick={onClick}
    >
      <AccordionItemState>
        {({ expanded }) => (
          <>
            <AccordionItemHeading>
              <AccordionItemButton
                css={`
                  padding: 20px 32px;
                  padding-bottom: ${expanded ? "12px" : "20px"};
                  outline: none;
                  display: flex;
                  opacity: ${expanded ? 1 : 0.7};
                  transition: opacity 250ms ease;
                  align-items: center;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 1.5;

                  @media (max-width: ${DEVICES.mobile}px) {
                    padding: 10px 16px;
                  }
                `}
              >
                <div
                  css={`
                    display: flex;
                    align-items: center;
                  `}
                >
                  {/* {expanded && (
                    <div
                      css={`
                        min-height: 28px;
                        min-width: 28px;
                        margin-right: 16px;
                      `}
                    >
                      <Progress />
                    </div>
                  )} */}

                  {summary}
                </div>
                {expanded && (
                  <div
                    css={`
                      display: flex;
                      margin-left: auto;
                      height: 16px;
                      width: 16px;
                    `}
                  >
                    <ChevronUp
                      css={`
                        fill: #ebeaea;
                        transition: transform 250ms ease;
                        ${expanded
                          ? css`
                              transform: rotate(180deg);
                            `
                          : ""}
                      `}
                    />
                  </div>
                )}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
              css={`
                padding: 0 32px;
                opacity: 0.55;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.29;
                margin-bottom: 32px;
                /* margin-left: 44px; */

                @media (max-width: ${DEVICES.mobile}px) {
                  margin-left: 28px;
                }
              `}
            >
              {children}
            </AccordionItemPanel>
          </>
        )}
      </AccordionItemState>
    </AccordionItem>
  )
}

function Slide({ name, image }) {
  return (
    <div
      css={`
        width: 100%;

        img {
          width: 100%;
          max-width: var(--slide-width);
        }
      `}
    >
      <img src={image} alt={name} />
    </div>
  )
}

function SnapSlider() {
  const [activeIndex, setActiveIndex] = useState(0)
  const [swiper, setSwiper] = useState(null)

  return (
    <div
      css={`
        display: grid;
        grid-template-columns: 1fr 1.1fr;

        /* --slide-width: 333px; */
        --slide-width: 333px;

        @media (max-width: ${DEVICES.desktop}px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          & > *:not(:last-child) {
            margin-bottom: 56px;
          }
        }

        @media (max-width: ${DEVICES.mobile}px) {
          --slide-width: 300px;
        }

        .swiper-container {
          max-width: var(--slide-width);
        }

        .swiper-pagination {
          position: static;
          text-align: left;
          align-self: center;
        }
      `}
    >
      <Swiper
        slidesPerView={1}
        pagination={{
          el: ".auto-slider-pagination",
          type: "custom",
        }}
        onSwiper={setSwiper}
        onSlideChange={swiper => setActiveIndex(swiper.activeIndex)}
      >
        {SLIDER_PAGES.map(page => (
          <SwiperSlide key={page.name}>
            <Slide {...page} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={`swiper-pagination auto-slider-pagination`}>
        <Accordion
          css={`
            display: grid;
            gap: 18px;
          `}
        >
          {SLIDER_PAGES.map((page, index) => (
            <Item
              summary={page.name}
              key={page.name}
              expanded={activeIndex === index}
              onClick={() => {
                swiper.slideTo(index)
                setActiveIndex(index)
              }}
            >
              {page.description}
            </Item>
          ))}
        </Accordion>
      </div>
    </div>
  )
}

export default SnapSlider
