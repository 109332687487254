import { Link } from "gatsby"
import React from "react"
import Button from "../common/Button"

function GetStarted({ plain, trigger }) {
  return plain ? (
    <Button.Plain as={Link} to="/payment">
      Get your PNOĒ test now
    </Button.Plain>
  ) : (
    <Button as={Link} to="/payment">
      Get your PNOĒ test now
    </Button>
  )
}

export default GetStarted
