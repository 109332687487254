import Button from "../common/Button"
import IconPlay from "../../assets/images/icon-play.inline.svg"
import React from "react"
import Popup from "reactjs-popup"
import styled from "styled-components"
import IconClose from "../../assets/images/icon-close.inline.svg"
import { MEDIA_QUERIES } from "../../config"

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.9);
  }
  &-content {
    padding: 0;
    border: none;
    background: rgba(0, 0, 0, 0.9);
    width: auto;
  }
`

function ExperiencePnoe(props) {
  return (
    <StyledPopup
      modal
      closeOnDocumentClick
      trigger={
        <Button
          mt={32}
          css={`
            padding: 8px 16px;
            display: inline-block;
          `}
          {...props}
        >
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            Experience PNOĒ{" "}
            <IconPlay
              css={`
                margin-left: 12px;
              `}
            />
          </div>
        </Button>
      }
    >
      {close => (
        <>
          <IconClose
            role="button"
            css={`
              position: fixed;
              right: 2rem;
              top: 2rem;
              fill: var(--text);
              cursor: pointer;
            `}
            onClick={close}
          />
          <div
            css={`
              width: 1024px;
              height: 576px;

              iframe {
                width: 100%;
                height: 100%;
              }

              ${MEDIA_QUERIES.belowDesktop} {
                width: 560px;
                height: 315px;
              }

              ${MEDIA_QUERIES.belowTablet} {
                width: 375px;
                height: 210px;
              }
            `}
          >
            <iframe
              src="https://www.youtube-nocookie.com/embed/kE6v9AxrOMw?autoplay=1&autohide=1&fs=1&rel=0&hd=1&wmode=transparent&enablejsapi=1&html5=1"
              title="YouTube video player"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </div>
        </>
      )}
    </StyledPopup>
  )
}

export default ExperiencePnoe
