import React from "react"
import { SwiperSlide } from "swiper/react"
import { SLIDER_PAGES } from "../../config"
import CustomSwiper from "../common/CustomSwiper"

function Slide({ name, description, image }) {
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <img
        css={`
          max-width: 220px;
          margin-bottom: 48px;
        `}
        src={image}
        alt={name}
      />
      <div
        css={`
          font-size: 14px;
          font-weight: bold;
          line-height: 1.4;
          text-align: center;
          margin-bottom: 16px;
        `}
      >
        {name}
      </div>
      <div
        css={`
          opacity: 0.7;
          font-size: 14px;
          font-weight: 300;
          line-height: 1.25;
          text-align: center;
          max-width: 720px;
          margin-bottom: 32px;
        `}
      >
        {description}
      </div>
    </div>
  )
}

function MobileSnapSlider() {
  return (
    <CustomSwiper
      id="autoslider"
      loop
      slidesPerView={1}
      navigation={{
        nextEl: ".autoslider-next",
        prevEl: ".autoslider-prev",
      }}
      pagination={{ clickable: true, el: ".autoslider-pagination" }}
      css={`
        .autoslider-next,
        .autoslider-prev {
          display: none;
        }

        .autoslider-pagination {
          position: static;
        }
      `}
    >
      {SLIDER_PAGES.map(page => (
        <SwiperSlide key={page.name}>
          <Slide {...page} />
        </SwiperSlide>
      ))}
    </CustomSwiper>
  )
}

export default MobileSnapSlider
