import React, { useState } from "react"
import Lottie from "react-lottie-player"
import hero from "../../assets/lottie/hero.json"
import Container from "../common/Container"
import imageHero1 from "../../assets/images/hero-1.png"
import imageHero2 from "../../assets/images/hero-2.png"
import CustomSwiper from "../common/CustomSwiper"
import { SwiperSlide } from "swiper/react"
import Divider from "../common/Divider"
import { DEVICES } from "../../config"
import GetStarted from "./GetStarted"

const TITLE = (
  <>
    Discover your breath, <br />
    Discover yourself.
  </>
)

const DESCRIPTION =
  "The PNOĒ breath test scans your metabolism, heart, and lungs with clinical accuracy and gives you the most effective nutrition, training, and recovery insights science can offer."

function HeroSlide({ title, description, image }) {
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        background: var(--main);
        padding-bottom: 2px;
        min-height: 538px;

        h2 {
          font-size: 32px;
          width: 532px;
          font-weight: bold;
          line-height: 1.25;
          margin: 0;
          margin-bottom: 16px;
        }

        h3 {
          font-size: 16px;
          line-height: 1.5;
          font-weight: normal;
        }

        @media (max-width: ${DEVICES.mobile}px) {
          flex-direction: column;

          h2 {
            font-size: 20px;
            text-align: center;
            width: unset;
            margin-top: 32px;
            text-align: center;
            padding: 0 16px;
          }

          h3 {
            display: none;
          }

          img {
            /* width: 100%; */
            margin-right: 90%;
          }
        }
      `}
    >
      <div
        css={`
          max-width: 358px;
        `}
      >
        <h2>{title}</h2>
        <h3>{description}</h3>
        <div
          css={`
            @media (max-width: ${DEVICES.mobile}px) {
              display: none;
            }
          `}
        >
          <div
            css={`
              margin-top: 32px;
            `}
          >
            <GetStarted />
          </div>
        </div>
      </div>
      {image}
    </div>
  )
}

function Hero() {
  const [index, setIndex] = useState(0)

  return (
    <Container
      light
      css={`
        position: relative;

        hr {
          position: relative;
          z-index: 10;
        }
      `}
    >
      <Container
        compact
        css={`
          @media (max-width: ${DEVICES.mobile}px) {
            width: 100%;
          }
        `}
      >
        <CustomSwiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          effect="fade"
          id="hero-slider"
          loop
          slidesPerView={1}
          css={`
            .hero-slider-next,
            .hero-slider-prev {
              display: none;
            }
          `}
          onSlideChange={swiper => setIndex(swiper.realIndex)}
        >
          <SwiperSlide>
            <HeroSlide
              title={TITLE}
              description={DESCRIPTION}
              image={
                <img
                  css={`
                    margin-left: -120px;
                    width: 525px;
                    height: auto;
                  `}
                  src={imageHero1}
                  alt="Hero 1"
                />
              }
            />
          </SwiperSlide>
          <SwiperSlide>
            <HeroSlide
              title={TITLE}
              description={DESCRIPTION}
              image={
                <img
                  css={`
                    margin-top: 30px;
                    width: 436px;
                    height: auto;
                  `}
                  src={imageHero2}
                  alt="Hero 2"
                />
              }
            />
          </SwiperSlide>
        </CustomSwiper>
        <Container
          compact
          css={`
            width: auto;
            height: 100%;
            position: absolute;
            top: ${index === 0 ? "200px" : "50px"};
            right: calc(50vw - 1024px / 2);
            z-index: 10;
            background: transparent;

            @media (max-width: ${DEVICES.mobile}px) {
              top: ${index === 0 ? "350px" : "120px"};
              right: 0;
            }
          `}
        >
          <Lottie
            play={true}
            animationData={hero}
            css={`
              height: 280px;
              width: 280px;
              margin-left: auto;

              @media (max-width: ${DEVICES.mobile}px) {
                height: 230px;
                width: 230px;
              }
            `}
          />
        </Container>
      </Container>
      <div
        css={`
          display: none;

          @media (max-width: ${DEVICES.mobile}px) {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        `}
      >
        <h3
          css={`
            padding: 32px 16px;
            font-size: 16px;
            line-height: 1.5;
            font-weight: normal;
            text-align: center;
            margin: 0;
          `}
        >
          {DESCRIPTION}
        </h3>
        <div
          css={`
            margin-top: 0;
            margin-bottom: 32px;
          `}
        >
          <GetStarted />
        </div>
      </div>

      <Divider
        css={`
          margin: 0;
        `}
      />
    </Container>
  )
}

export default Hero
