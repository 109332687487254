import React from "react"
import { DEVICES, BRANDS } from "../../config"
import Container from "../common/Container"

function Brands() {
  return (
    <Container
      light
      css={`
        padding: 32px 0;
      `}
    >
      <h2
        css={`
          font-size: 20px;
          font-weight: bold;
          line-height: 1.4;
          margin: 0;
          margin-bottom: 28px;
          text-align: center;

          @media (max-width: ${DEVICES.tablet}px) {
            padding: 0 16px;
          }
        `}
      >
        Trusted by the best
      </h2>
      <Container
        css={`
          max-width: 1280px;
          margin: 0 auto;
        `}
      >
        <div
          css={`
            img {
              width: 90px;
              height: auto;
            }
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-items: center;
            gap: 16px;

            img.acsm {
              width: 78.5px;
            }

            img.spartan {
              width: 160px;
            }

            img.whoop {
              width: 151px;
            }

            img.ciclismo {
              width: 133px;
            }

            @media (max-width: ${DEVICES.tablet}px) {
              grid-auto-flow: unset;
              grid-template-columns: 1fr 1fr;
              gap: 30px;
              justify-items: center;

              img {
                width: 120px;
              }

              img.acsm {
                width: 78.5px;
              }

              img.spartan {
                width: 120px;
                grid-column: 1 / 3;
              }

              img.whoop {
                width: 120px;
              }
            }
          `}
        >
          {BRANDS.map(({ src, alt, ...props }) => (
            <img key={alt} src={src} alt={alt} className={alt} {...props} />
          ))}
        </div>
      </Container>
    </Container>
  )
}

export default Brands
