import React, { useState } from "react"
import { SwiperSlide } from "swiper/react"
import report from "../../../static/pnoe-fitness-report.pdf"
import breathMobile from "../../assets/images/breath-mobile.jpg"
import breathTablet from "../../assets/images/breath-tablet.jpg"
import breath from "../../assets/images/breath.jpg"
import IconDownload from "../../assets/images/icon-download.inline.svg"
import Button from "../common/Button"
import CustomSwiper from "../common/CustomSwiper"
import { BREATHS, DEVICES } from "../../config"
import useWindowSize from "../../hooks/useWindowSize"

const BREATHS_ONE = BREATHS.filter(x => x.group === 1)
const BREATHS_TWO = BREATHS.filter(x => x.group === 2)

const TITLE =
  "All the data to optimize your nutrition, training, and recovery are in your breath"

function BreathMobile() {
  const [selected, setSelected] = useState(BREATHS[0])
  const [swiper, setSwiper] = useState(null)

  return (
    <>
      <div
        css={`
          padding: 24px 32px;
        `}
      >
        <h2
          css={`
            font-size: 24px;
            font-weight: bold;
            line-height: 1.33;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 16px;
            max-width: 326px;
          `}
        >
          {TITLE}
        </h2>
      </div>
      <div
        css={`
          position: relative;
          min-height: 431px;
        `}
      >
        <div
          css={`
            z-index: 1;
            display: inline-block;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(
              to bottom,
              rgba(25, 25, 25, 0) 13%,
              #191919 99%,
              #191919 99%
            );
          `}
        ></div>
        <img
          css={`
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            position: absolute;
            vertical-align: bottom;
          `}
          src={breathMobile}
          alt="man running"
        />
        <div
          css={`
            max-width: 450px;
            margin: 0 auto;
            padding: 13px 44px;
            position: relative;
            z-index: 10;
          `}
        >
          <div
            css={`
              display: flex;
              justify-content: space-between;
            `}
          >
            <div
              css={`
                & > *:not(:last-child) {
                  margin-bottom: 8px;
                }
              `}
            >
              {BREATHS_ONE.map((breath, index) => (
                <div key={breath.name}>
                  <BreathLink
                    css={`
                      padding: 10px;
                    `}
                    icon={breath.icon}
                    isSelected={breath.name === selected.name}
                    onClick={() => swiper.slideTo(index)}
                  />
                </div>
              ))}
            </div>
            <div
              css={`
                & > *:not(:last-child) {
                  margin-bottom: 8px;
                }
              `}
            >
              {BREATHS_TWO.map((breath, index) => (
                <div key={breath.name}>
                  <BreathLink
                    css={`
                      padding: 10px;
                    `}
                    icon={breath.icon}
                    isSelected={breath.name === selected.name}
                    onClick={() => swiper.slideTo(BREATHS_ONE.length + index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <BreathSwiper
        onSlideChange={swiper => setSelected(BREATHS[swiper.activeIndex])}
        onSwiper={setSwiper}
      />
    </>
  )
}

function BreathSwiper({ onSwiper, onSlideChange }) {
  return (
    <>
      <div
        css={`
          margin: 0 auto;
          margin-top: -100px;
          max-width: 500px;
        `}
      >
        <CustomSwiper
          id="breath"
          slidesPerView={1}
          arrowSize={24}
          navigation={{
            nextEl: ".breath-next",
            prevEl: ".breath-prev",
          }}
          onSwiper={onSwiper}
          onSlideChange={onSlideChange}
        >
          {BREATHS.map(breath => (
            <SwiperSlide key={breath.name}>
              <BreathSlide
                icon={breath.icon}
                name={breath.name}
                description={breath.description}
              />
            </SwiperSlide>
          ))}
        </CustomSwiper>
      </div>
      <div
        css={`
          display: flex;
          justify-content: center;
          margin: 32px 0;
        `}
      >
        <SampleReportButton />
      </div>
    </>
  )
}

function BreathSlide({ icon, name, description }) {
  return (
    <div
      css={`
        background: var(--main);
        color: var(--text);
        border: 2px solid #bf0049;
        border-radius: 6px;
        max-width: 265px;
        min-height: 250px;
        margin: 0 auto;
        padding: 16px;
        align-items: center;
        display: flex;
        flex-direction: column;

        svg.auto-svg {
          width: 20px;
          height: 20px;
          fill: #fff;
        }

        span {
          margin-left: 8px;
        }
      `}
    >
      <div
        css={`
          display: flex;
          align-items: center;
          margin-bottom: 16px;
        `}
      >
        {icon}
        <span>{name}</span>
      </div>
      <div
        css={`
          opacity: 0.7;
          font-size: 14px;
          font-weight: 300;
          line-height: 1.45;
          text-align: center;
        `}
      >
        {description}
      </div>
    </div>
  )
}

function BreathTablet() {
  const [selected, setSelected] = useState(BREATHS_ONE[0])

  return (
    <div
      css={`
        position: relative;
        min-height: 690px;
      `}
    >
      <div
        css={`
          z-index: 1;
          display: inline-block;
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: linear-gradient(
            to left,
            rgba(25, 25, 25, 0),
            #191919 60%
          );
        `}
      ></div>
      <img
        css={`
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: right;
          position: absolute;
          vertical-align: bottom;
        `}
        src={breathTablet}
        alt="man running"
      />
      <div
        css={`
          display: flex;
          position: relative;
          z-index: 10;
        `}
      >
        <div
          css={`
            margin: 185px 0 185px 40px;
          `}
        >
          <h2
            css={`
              font-size: 24px;
              font-weight: bold;
              line-height: 1.5;
              margin: 0;
              max-width: 408px;
            `}
          >
            {TITLE}
          </h2>
          <SampleReportButton />
        </div>
        <div
          css={`
            max-width: 522px;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: right;
            position: absolute;
            right: 40px;
            top: 32px;
          `}
        >
          <div
            css={`
              max-width: 450px;
              margin: 0 auto;
              margin-bottom: 40px;
              padding: 13px 44px;
              position: relative;
              z-index: 10;
            `}
          >
            <div
              css={`
                display: flex;
                justify-content: space-between;
              `}
            >
              <div
                css={`
                  & > *:not(:last-child) {
                    margin-bottom: 8px;
                  }
                `}
              >
                {BREATHS_ONE.map((breath, index) => (
                  <div key={breath.name}>
                    <BreathLink
                      css={`
                        padding: 10px;
                      `}
                      icon={breath.icon}
                      isSelected={breath.name === selected.name}
                      onClick={() => setSelected(breath)}
                    />
                  </div>
                ))}
              </div>
              <div
                css={`
                  & > *:not(:last-child) {
                    margin-bottom: 8px;
                  }
                `}
              >
                {BREATHS_TWO.map((breath, index) => (
                  <div key={breath.name}>
                    <BreathLink
                      css={`
                        padding: 10px;
                      `}
                      icon={breath.icon}
                      isSelected={breath.name === selected.name}
                      onClick={() => setSelected(breath)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            css={`
              width: 100%;
              margin: 0 auto;
              max-width: 300px;
              background: var(--main);
              color: var(--text);
              border-radius: 6px;
              padding: 20px 24px;
              border: solid 2px #bf0049;
              transition: border ease 250ms;
              display: flex;
            `}
          >
            <div>
              <h3
                css={`
                  font-size: 16px;
                  font-weight: bold;
                  line-height: 1.5;
                  margin: 0;
                  margin-bottom: 3px;
                `}
              >
                {selected.name}
              </h3>
              <p
                css={`
                  opacity: 0.7;
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 1.45;
                  margin: 0;
                `}
              >
                {selected.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function BreathWide({ windowWidth }) {
  const [selected, setSelected] = useState(BREATHS_ONE[0])

  return (
    <div
      css={`
        position: relative;
        min-height: 690px;
      `}
    >
      <div
        css={`
          z-index: 1;
          display: inline-block;
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: linear-gradient(
            to left,
            rgba(25, 25, 25, 0),
            #191919 60%
          );
        `}
      ></div>
      <img
        css={`
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: right;
          position: absolute;
          vertical-align: bottom;
        `}
        src={windowWidth < 1350 ? breathTablet : breath}
        alt="man running"
      />
      <div
        css={`
          display: flex;
          position: relative;
          z-index: 10;
        `}
      >
        <div
          css={`
            margin: 185px 0 185px 208px;

            ${windowWidth < 1350 ? `margin: 185px 0 185px 60px` : ``}
          `}
        >
          <h2
            css={`
              font-size: 32px;
              font-weight: bold;
              line-height: 1.5;
              margin: 0;
              max-width: 408px;
            `}
          >
            {TITLE}
          </h2>
          <SampleReportButton />
        </div>
        <div
          css={`
            max-width: 522px;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: right;
            position: absolute;
            right: ${windowWidth < 1350 ? "60px" : "208px"};
            top: 32px;
          `}
        >
          <div
            css={`
              display: flex;
              margin-bottom: 40px;
            `}
          >
            <div
              css={`
                display: grid;
                gap: 40px;
                grid-template-rows: repeat(5, 1fr);
                margin-right: 172px;
              `}
            >
              {BREATHS_ONE.map(breath => (
                <div key={breath.name}>
                  <BreathLink
                    name={breath.name}
                    icon={breath.icon}
                    isSelected={breath.name === selected.name}
                    onClick={() => setSelected(breath)}
                  />
                </div>
              ))}
            </div>
            <div
              css={`
                display: grid;
                gap: 40px;
                grid-template-rows: repeat(5, 1fr);
              `}
            >
              {BREATHS_TWO.map(breath => (
                <div key={breath.name}>
                  <BreathLink
                    name={breath.name}
                    icon={breath.icon}
                    isSelected={breath.name === selected.name}
                    onClick={() => setSelected(breath)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            css={`
              width: 100%;
              background: var(--main);
              color: var(--text);
              border-radius: 6px;
              padding: 20px 24px;
              border: solid 2px #bf0049;
              transition: border ease 250ms;
              display: flex;
            `}
          >
            <div>
              <h3
                css={`
                  font-size: 16px;
                  font-weight: bold;
                  line-height: 1.5;
                  margin: 0;
                  margin-bottom: 3px;
                `}
              >
                {selected.name}
              </h3>
              <p
                css={`
                  opacity: 0.7;
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 1.45;
                  margin: 0;
                `}
              >
                {selected.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function BreathLink({ name, icon, isSelected, ...props }) {
  return (
    <a
      css={`
        background: var(--main);
        color: var(--text);
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 6px 8px;
        border-style: solid;
        border-width: 2px;
        transition: border-color ease 250ms;
        max-width: 175px;
        font-size: 14px;
        line-height: 1.29;
        border-color: ${isSelected ? "#bf0049" : "rgba(0, 0, 0, 0.8)"};
        cursor: pointer;
        span {
          opacity: 0.7;
          transition: opacity ease 250ms;
          margin-left: 12px;
        }

        svg.auto-svg {
          width: 28px;
          height: 28px;
          align-self: flex-start;
          fill: #fff;
          opacity: 0.7;
          transition: opacity ease 250ms;
        }

        &:hover {
          border-color: #bf0049;
          span {
            opacity: 1;
          }
          svg.auto-svg {
            opacity: 1;
          }
        }
      `}
      {...props}
    >
      {icon}
      {name && <span>{name}</span>}
    </a>
  )
}

function SampleReportButton(props) {
  return (
    <Button
      css={`
        margin-top: 32px;
      `}
      {...props}
      as="a"
      href={report}
      download
    >
      <div
        css={`
          display: flex;
          align-items: center;
        `}
      >
        Sample Report
        <IconDownload
          css={`
            margin-left: 11px;
            fill: #0095ff;
          `}
        />
      </div>
    </Button>
  )
}

function Breathing() {
  const { width } = useWindowSize()

  return width >= DEVICES.desktop ? (
    <BreathWide windowWidth={width} />
  ) : width >= 840 ? (
    <BreathTablet />
  ) : (
    <BreathMobile />
  )
}

export default Breathing
