import { Field, Formik, Form } from "formik"
import React from "react"
import Container from "../common/Container"
import FormikInput from "../common/FormikInput"
import * as Yup from "yup"
import Button from "../common/Button"
import { DEVICES } from "../../config"
import Cookies from "js-cookie"
import axios from "axios"

function InfoForm() {
  return (
    <Container
      light
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 72px 0;
        text-align: center;

        h2 {
          font-size: 20px;
          font-weight: bold;
          line-height: 1.4;
          margin: 0;
          margin-bottom: 12px;
        }

        h4 {
          max-width: 750px;
          opacity: 0.7;
          font-size: 16px;
          line-height: 1.5;
          font-weight: normal;
          margin: 0;
          margin-bottom: 32px;
        }

        form {
          max-width: 459px;
          display: grid;
          gap: 16px;
          grid-template-columns: 1fr 1fr;

          & > :nth-child(3) {
            grid-column: 1 / 3;
          }

          & > :nth-child(4) {
            grid-column: 1 / 3;
          }
        }

        @media (max-width: ${DEVICES.mobile}px) {
          padding: 32px;
          padding-bottom: 72px;

          form {
            grid-template-columns: 1fr;

            & > :nth-child(3) {
              grid-column: 1;
            }

            & > :nth-child(4) {
              grid-column: 1;
              margin-top: 8px;
            }
          }
        }
      `}
    >
      <h2>Become your own sports scientist</h2>
      <h4>
        Sign up and get the intro module of the PNOĒ Certification for FREE, the
        world's first online course that teaches you how to use any biometric
        datapoint to optimize your training and nutrition.
      </h4>
      <Formik
        initialValues={{ name: "", surname: "", email: "" }}
        validationSchema={Yup.object({
          name: Yup.string().required(),
          surname: Yup.string().required(),
          email: Yup.string().email().required(),
        })}
        onSubmit={async (values, { resetForm }) => {
          const isBrowser = typeof window !== "undefined"
          const hutk = isBrowser ? Cookies.get("hubspotutk") : null
          const pageUri = isBrowser ? window.location.href : null
          const pageName = isBrowser ? document.title : null
          const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/5241029/a0abdb04-dd8f-499b-b62d-174632f353b7`

          try {
            await axios.post(postUrl, {
              submittedAt: Date.now(),
              fields: [
                {
                  name: "firstname",
                  value: values.name,
                },
                {
                  name: "lastname",
                  value: values.surname,
                },
                {
                  name: "email",
                  value: values.email,
                },
              ],
              context: {
                hutk,
                pageUri,
                pageName,
              },
            })
          } catch (error) {
            console.error(error)
          } finally {
            resetForm()
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              component={FormikInput}
              name="name"
              placeholder="Your Name"
            />
            <Field
              component={FormikInput}
              name="surname"
              placeholder="Your Surname"
            />
            <Field
              component={FormikInput}
              name="email"
              placeholder="Your Email Address"
            />
            <div>
              <Button
                disabled={isSubmitting}
                type="submit"
                css={`
                  opacity: ${isSubmitting ? 0.5 : 1};
                  padding: 12px 53px;
                `}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default InfoForm
