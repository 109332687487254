import React from "react"
import { DEVICES } from "../../config"
import Container from "../common/Container"
import imageIntroducingPnoe from "../../assets/images/introducing-pnoe.jpg"
import ExperiencePnoe from "./ExperiencePnoe"
import useWindowSize from "../../hooks/useWindowSize"
import Img from "gatsby-image"

const DESC = (
  <>
    <p>
      For 100+ years, Breath Analysis has been the most trusted tool for
      analyzing your metabolism, heart, and lungs and personalizing training,
      nutrition, and recovery.
    </p>
    <p>
      PNOĒ brought it to your living room along with the most holistic
      performance optimization program.
    </p>
    <p
      css={`
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
      `}
    >
      Get started by renting a PNOĒ device for 48 hours.
    </p>
  </>
)

function IntroducingPnoe({ fluid }) {
  const { width } = useWindowSize()

  return width < 1130 ? (
    <Container light>
      <div
        css={`
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: transparent;
          position: relative;
          z-index: 10;
          /* padding: 48px 0; */
          padding-bottom: 48px;
          align-items: center;
          text-align: center;

          .gatsby-image-wrapper {
            width: 100%;
            margin: 48px 0;
          }

          h2 {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.33;
            max-width: 390px;
            margin: 0;
          }

          p {
            font-size: 18px;
            line-height: 1.33;
            max-width: 390px;
            margin: 0;
            margin-bottom: 24px;
          }

          a {
            max-width: 178px;
            padding: 8px 16px;
            text-align: center;
          }

          @media (max-width: ${DEVICES.mobile}px) {
            h2 {
              font-size: 18px;
              line-height: 1.33;
              padding: 0 32px;
              padding-bottom: 16px;
            }

            p {
              font-size: 16px;
              line-height: 1.5;
              padding: 0 32px;
            }

            a {
              max-width: unset;
              /* margin-top: 32px; */
            }

            .gatsby-image-wrapper {
              margin-bottom: 32px;
              margin-top: 0;
            }
          }
        `}
      >
        {/* {TITLE} */}
        <Img fluid={fluid} />
        {DESC}
        <div>
          <ExperiencePnoe mt={0} />
        </div>
        {/* <GetStarted.Tail /> */}
      </div>
    </Container>
  ) : (
    <Container
      light
      css={`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div
        css={`
          flex: 1;
          display: flex;
          justify-content: center;
          padding-left: 48px;
        `}
      >
        <div
          css={`
            display: flex;
            min-height: 527px;
            flex-direction: column;
            justify-content: center;
            background: transparent;
            position: relative;
            z-index: 10;

            h2 {
              font-size: 24px;
              font-weight: bold;
              line-height: 1.33;
              max-width: 390px;
              margin: 0;
              margin-bottom: 16px;
            }

            p {
              font-size: 16px;
              line-height: 1.5;
              max-width: 390px;
              margin: 0;
              margin-bottom: 24px;
            }

            a {
              max-width: 269px;
              padding: 8px 16px;
              text-align: center;
            }
          `}
        >
          {/* {TITLE} */}
          {DESC}
          <div>
            <ExperiencePnoe mt={0} />
          </div>
          {/* <GetStarted.Tail /> */}
        </div>
      </div>
      <img
        css={`
          max-height: 527px;
          align-self: flex-end;

          @media (max-width: ${DEVICES.desktop}px) {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        `}
        src={imageIntroducingPnoe}
        alt="Introducing Pnoe"
      />
    </Container>
  )
}

export default IntroducingPnoe
