import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/common/Layout"
import SEO from "../components/common/SEO"
import Brands from "../components/home/Brands"
import Breathing from "../components/home/Breathing"
import Hero from "../components/home/Hero"
import InfoForm from "../components/home/InfoForm"
import IntroducingPnoe from "../components/home/IntroducingPnoe"
import Slider from "../components/home/Slider"
import Stats from "../components/home/Stats"
import Testimonials from "../components/home/Testimonials"
import PnoeAi from "../components/home/PnoeAi"
import NewHowItWorks from "../components/home/NewHowItWorks"
import Divider from "../components/common/Divider"

function Home({ data }) {
  return (
    <Layout>
      <SEO title="Home" />
      <Divider.Light />
      <main>
        <Hero />
        <IntroducingPnoe fluid={data.intro.childImageSharp.fluid} />
        <Brands />
        <Divider.Light />
        <NewHowItWorks />
        <Divider.Light />
        <PnoeAi />
        <Divider.Light />
        <Testimonials />
        <Slider />
        <Stats />
        <Divider.Light />
        <InfoForm />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    intro: file(relativePath: { eq: "introducing-pnoe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1628, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Home
