import React from "react"
import { useInView } from "react-intersection-observer"
import Lottie from "react-lottie-player"
import pnoeAiDesktop from "../../assets/lottie/pnoe-ai-desktop.json"
import pnoeAiMobile from "../../assets/lottie/pnoe-ai-mobile.json"
import { DEVICES } from "../../config"
import useWindowSize from "../../hooks/useWindowSize"
import Container from "../common/Container"

function PnoeAi() {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const { width } = useWindowSize()

  return (
    <Container
      light
      ref={ref}
      css={`
        padding: 48px 0;
      `}
    >
      <Lottie
        play={inView}
        loop={false}
        animationData={width <= DEVICES.mobile ? pnoeAiMobile : pnoeAiDesktop}
        css={`
          margin: 0 auto;
          height: 800px;

          @media (max-width: ${DEVICES.mobile}px) {
            width: 90%;
            height: unset;
          }
        `}
      />
    </Container>
  )
}

export default PnoeAi
